@import './_all_vars.sass'

:root
  --switcher-dropdown-background-colour: #{$recall-dropdown-background}
  --switcher-dropdown-button-colour: #{$recall-primary-black}
  --switcher-dropdown-active-text-colour: #{$recall-primary-white}
  --switcher-dropdown-active-background-colour: #{$recall-active-background-colour}
  --switcher-text-colour: #{$recall-primary-black}
  --switcher-background-colour: #{$recall-primary-white}
  --winwise-background-colour: #{$recall-winwise-button-background}

div:global(.pageThemeDark)
  --switcher-dropdown-background-colour: #{$recall-dropdown-background-darkmode}
  --switcher-dropdown-button-colour: #{$recall-primary-white}
  --switcher-dropdown-active-text-colour: #{$recall-primary-black}
  --switcher-dropdown-active-background-colour: #{$recall-dropdown-background}
  --switcher-text-colour: #{$recall-primary-white}
  --switcher-background-colour: #{$recall-primary-black-darkmode}
  --winwise-background-colour: #{$recall-winwise-button-background-darkmode}

.chatSwitcherContainer
  position: fixed
  top: 10px
  right: 28px
  width: 215px
  background-color: var(--switcher-background-colour)
  z-index: 10000
  border-radius: 8px

  .selectedChat
    display: flex
    border: 1px solid
    border-radius: 5px
    cursor: pointer
    border-color: $recall-code-chat-disabled

    &.recall
      border-color: $recall-recall-chat

    &.chatGpt
      border-color: $recall-gpt-chat

    &.winwise
      border-color: var(--winwise-background-colour)

    &.bamboo
      border-color: $recall-bamboo-chat

    &.cra
      border-color: $recall-cra

    &.bhp
      border-color: $recall-bhp-chat
    
    &.rioTinto
      border-color: $recall-rio-tinto-chat

    &.ausNet
      border-color: $recall-ausnet-chat

    &.pmApp
      border-color: $recall-pm-chat
    
    &.code
      border-color: $recall-code-chat

    .label
      padding: 10px
      border-top-left-radius: 5px
      border-bottom-left-radius: 5px
      color: var(--switcher-text-colour)
      width: 80%
      display: flex

      .icon
        position: relative
        top: 5px
        border-radius: 50%
        width: 12px
        height: 12px
        margin: 0 10px

        &.recall
          background-color: $recall-recall-chat

        &.chatGpt
          background-color: $recall-gpt-chat

        &.winwise
          background-color: var(--winwise-background-colour)

        &.bamboo
          background-color: $recall-bamboo-chat

        &.cra
          background-color: $recall-cra

        &.bhp
          background-color: $recall-bhp-chat

        &.rioTinto
          background-color: $recall-rio-tinto-chat
 
        &.ausNet
          background-color: $recall-ausnet-chat

        &.pmApp
          background-color: $recall-pm-chat

        &.code
          background-color: $recall-code-chat

      .topLabel
        overflow: hidden
        width: 99%
        text-overflow: ellipsis
        white-space: nowrap

    .expandIcon
      padding: 10px

  .chatItemsContainer
    position: absolute
    width: 215px
    border-radius: 5px
    margin-top: 1px
    box-shadow: 0px 0px 4px $primary--text--colour
    max-height: 80vh
    overflow-y: auto

    .chatItems
      display: flex
      flex-direction: column
      align-items: flex-start

      .item
        color: var(--switcher-dropdown-button-colour)
        background-color: var(--switcher-dropdown-background-colour)
        padding: 10px
        cursor: pointer
        overflow: hidden
        width: 99%
        text-overflow: ellipsis
        white-space: nowrap
        text-align: left

        &:hover
          color: var(--switcher-dropdown-active-text-colour)
          background-color: var(--switcher-dropdown-active-background-colour)
          cursor: pointer
        &.active
          color: var(--switcher-dropdown-active-text-colour)
          background-color: var(--switcher-dropdown-active-background-colour)

      .item:first-child
        border-top-right-radius: 5px
        border-top-left-radius: 5px

      .item:last-child
        border-bottom-right-radius: 5px
        border-bottom-left-radius: 5px

@media (max-width: 768px)
  .chatSwitcherContainer
    position: fixed
    top: 10px
    right: 10px
