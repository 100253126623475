@import './_all_vars.sass'

:root
  --chat-question-separator: #{$recall-separator}
  --related-question-border-colour: #{$recall-primary}
  --related-questions-hover: #{$recall-cra-overflowmenu-menu-options-hover}

div:global(.pageThemeDark)
  --chat-question-separator: #{$recall-separator-darkmode}
  --related-question-border-colour: #{$recall-green-300-darkmode}
  --related-questions-hover: #{$recall-cra-overflowmenu-menu-options-hover-darkmode}

.hasFiles
  margin-bottom: 200px !important

.question
  display: flex
  flex-direction: column
  border-top: 1px solid var(--chat-question-separator)
  margin-bottom: 20px

  .title
    font-size: 14px
    margin: 10px 0px
    line-height: 18px
    font-weight: 700


  .questionItem
    display: flex
    cursor: pointer
    border: 1px solid var(--related-question-border-colour)
    border-radius: 20px
    margin: 6px 0px
    padding: 8px 12px
    font-size: 14px
    font-weight: 400
    line-height: 21px
    gap: 12px

    &:hover
        background: var(--related-questions-hover)

    .icon
        padding-top: 8px