@import './_all_vars.sass'

:root
  --appShortcut-background-colour-hover: #{$recall-app-tile-border}
  --appShortcut-text-colour: #{$primary--text--colour}

div:global(.pageThemeDark)
  --appShortcut-background-colour-hover: #{$recall-menu-background-darkmode}
  --appShortcut-text-colour: #{$recall-primary-text-colour-darkmode}

.shortcut
  display: flex
  cursor: pointer
  padding: 16px
  border-radius: 8px
  width: fit-content
  margin: auto
  color: var(--appShortcut-text-colour)

  &:hover
    background-color: var(--appShortcut-background-colour-hover)
  
  :global(.iconRecallMenu1)
    fill: var(--appShortcut-text-colour)

  :global(.iconRecallMenu2)
    fill: var(--appShortcut-text-colour)

  :global(.iconWinWise1)
    fill: var(--appShortcut-text-colour)
  
  :global(.iconWinWise2)
    fill: var(--appShortcut-text-colour)

  :global(.iconChatgtpMenu)
    fill: var(--appShortcut-text-colour)
  
  :global(.iconBambooLogo)
    fill: var(--appShortcut-text-colour)
  
  :global(.iconCra1)
    fill: var(--appTile-primary-text-colour)

  :global(.iconCra2)
    fill: var(--appTile-icon-primary-color)

  :global(.pmApp1)
    fill: var(--appShortcut-text-colour)

  :global(.pmApp2)
    fill: var(--appShortcut-text-colour)

  :global(.clientSenseLogo)
    fill: var(--appShortcut-text-colour)
    
  :global(.iconCustomRecall)
    fill: var(--appShortcut-text-colour)

  .logo
    padding-right: 8px
    height: 40px
    width: 40px

  .content
    align-content: space-around
