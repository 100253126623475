@import '../_all_vars'

:root
    --bamboo-button-background: #{$recall-bamboo-button-background}
    --bamboo-button-hover-background: #{$recall-bamboo-button-background-hover}
    --bamboo-button-primary-color: #{$recall-bamboo-button-color}
    --bamboo-button-secondary-color: #{$recall-bamboo-button-secondary-color}
    --bamboo-button-icon-color: #{$recall-bamboo-button-icon-color}
    --bamboo-button-border-focus: #{$recall-button-border-focus}

    --cra-button-background: #{$recall-cra-button-background}
    --cra-button-primary-color: #{$recall-cra-button-color}
    --cra-button-secondary-color: #{$recall-cra-button-secondary-color}
    --cra-button-icon-color: #{$recall-cra-button-icon-color}
    --cra-button-border-focus: #{$recall-button-border-focus}

    --recall-button-background: #{$recall-recall-chat}
    --recall-button-hover-background: #{$recall-recall-chat-hover}
    --recall-button-primary-color: #{$recall-recall-chat-color}
    --recall-button-secondary-color: #{$recall-chat-button-secondary-color}
    --recall-button-icon-color: #{$recall-chat-button-icon-color}
    --recall-button-border-focus: #{$recall-chat-button-border-focus}

    --admin-post-secondary-btn: #{$recall-slides-primary-black}
    --admin-post-secondary-btn-hover: #{$recall-slides-primary-white}

    --winwise-button-secondary-color: #{$recall-primary-black}
    --winwise-button-secondary-hover-color: #{$recall-primary-white}
    --winwise-button-text-disabled-color: #{$winwise-button-text-disabled-color}
    --winwise-button-background-disabled-colour: #{$winwise-button-background-disabled-darkmode}

    --winwise-button-background-colour: #{$winwise-button-background}
    --winwise-button-background-colour-hover: #{$winwise-button-background-hover}
    --winwise-button-text-color: #{$recall-primary-white}
    

div:global(.pageThemeDark)
    --bamboo-button-background: #{$recall-bamboo-button-background-darkmode}
    --bamboo-button-hover-background: #{$recall-bamboo-button-background-hover-darkmode}
    --bamboo-button-primary-color: #{$recall-bamboo-button-color-darkmode}
    --bamboo-button-secondary-color: #{$recall-bamboo-button-secondary-color-darkmode}
    --bamboo-button-icon-color: #{$recall-bamboo-button-icon-color-darkmode}
    --bamboo-button-border-focus: #{$recall-button-border-focus-darkmode}

    --cra-button-background: #{$recall-cra-button-background-darkmode}
    --cra-button-primary-color: #{$recall-cra-button-color-darkmode}
    --cra-button-secondary-color: #{$recall-cra-button-secondary-color-darkmode}
    --cra-button-icon-color: #{$recall-cra-button-icon-color-darkmode}
    --cra-button-border-focus: #{$recall-button-border-focus-darkmode}

    --recall-button-background: #{$recall-chat-button-background-darkmode}
    --recall-button-primary-color: #{$recall-chat-button-color-darkmode}
    --recall-button-secondary-color: #{$recall-chat-button-secondary-color-darkmode}
    --recall-button-icon-color: #{$recall-chat-button-icon-color-darkmode}
    --recall-button-border-focus: #{$recall-button-border-focus-darkmode}

    --admin-post-secondary-btn: #{$recall-slides-primary-white}
    --admin-post-secondary-btn-hover: #{$recall-slides-primary-black}
    
    --winwise-button-secondary-color: #{$recall-primary-white}
    --winwise-button-secondary-hover-color: #{$recall-primary-black}
    --winwise-button-text-disabled-color: #{$winwise-button-text-disabled-color}
    --winwise-button-background-disabled-colour: #{$winwise-button-background-disabled-darkmode}

    --winwise-button-background-colour: #{$winwise-button-background-darkmode}
    --winwise-button-background-colour-hover: #{$winwise-button-background-hover-darkmode}
    --winwise-button-text-color: #{$recall-primary-black}

.modalWrapper
    div:global(.modal)
        box-shadow: 0px 0px 4px $recall-text-lighter !important

    div:global(.modal .modal-close-icon)
        color: var(--bamboo-button-icon-color)

    .default
        &.actionButtons
            border-radius: 24px
     
        &.actionButtons.secondary 
            border: 1px solid var(--admin-post-secondary-btn)
            color: var(--admin-post-secondary-btn)
            &:hover
                background-color: var(--admin-post-secondary-btn) !important
                border: 1px solid var(--admin-post-secondary-btn) !important
                color: var(--admin-post-secondary-btn-hover) !important
            
        
    .bamboo
        &.actionButtons
            border-radius: 24px

            &.primary
                color: var(--bamboo-button-primary-color) !important
                background-color: var(--bamboo-button-background) !important
                border: 1px solid var(--bamboo-button-background) !important
                    
                &:disabled
                    color: var(--bamboo-button-text-disabled) !important
                    background: var(--bamboo-button-background-disabled) !important
                    border: 1px solid var(--bamboo-button-background-disabled) !important
                    pointer-events: none !important

            &.secondary
                color: var(--bamboo-button-secondary-color) !important
                background-color: none
                border: 1px solid var(--bamboo-button-icon-color)
                margin-right: 0 !important

            &:hover:enabled
                background-color: var(--bamboo-button-hover-background) !important
                color: var(--bamboo-button-primary-color) !important
                border: 1px solid var(--bamboo-button-hover-background) !important

            &:focus:enabled
                background-color: var(--bamboo-button-border-focus) !important
                color: var(--bamboo-button-primary-color) !important
                border: 1px solid var(--bamboo-button-border-focus) !important

    .cra
        &.actionButtons
            border-radius: 24px

            &.primary
                color: var(--bamboo-button-primary-color) !important
                background-color: var(--cra-button-background) !important
                border: 1px solid var(--cra-button-background) !important
                    
                &:disabled
                    color: var(--bamboo-button-text-disabled) !important
                    background: var(--bamboo-button-background-disabled) !important
                    border: 1px solid var(--bamboo-button-background-disabled) !important
                    pointer-events: none !important

            &.secondary
                color: var(--bamboo-button-secondary-color) !important
                background-color: none
                border: 1px solid var(--bamboo-button-icon-color)
                margin-right: 0 !important

            &:hover:enabled
                background-color: var(--bamboo-button-hover-background) !important
                color: var(--bamboo-button-primary-color) !important
                border: 1px solid var(--bamboo-button-hover-background) !important

            &:focus:enabled
                background-color: var(--bamboo-button-border-focus) !important
                color: var(--bamboo-button-primary-color) !important
                border: 1px solid var(--bamboo-button-border-focus) !important

    .recall
        &.actionButtons
            border-radius: 24px

            &.primary
                color: var(--recall-button-primary-color) !important
                background-color: var(--recall-button-background) !important
                border: 1px solid var(--recall-button-background) !important
                    
                &:disabled
                    color: var(--recall-button-text-disabled) !important
                    background: var(--recall-button-background-disabled) !important
                    border: 1px solid var(--recall-button-background-disabled) !important
                    pointer-events: none !important

            &.secondary
                color: var(--recall-button-secondary-color) !important
                background-color: none
                border: 1px solid var(--recall-button-icon-color)
                margin-right: 0 !important

            &:hover:enabled
                background-color: var(--recall-button-hover-background) !important
                color: var(--recall-button-primary-color) !important
                border: 1px solid var(--recall-button-hover-background) !important

            &:focus:enabled
                background-color: var(--recall-button-border-focus) !important
                color: var(--recall-button-primary-color) !important
                border: 1px solid var(--recall-button-border-focus) !important

    .winwise
        &.actionButtons
            border-radius: 24px

            &.primary
                color: var(--winwise-button-text-color) !important
                background-color: var(--winwise-button-background-colour) !important
                border: 1px solid var(--winwise-button-background-colour) !important
                    
                &:disabled
                    color: var(--winwise-button-text-disabled-color) !important
                    background: var(--winwise-button-background-disabled-colour) !important
                    border: 1px solid var(--winwise-button-background-disabled-colour) !important
                    pointer-events: none !important

            &.secondary
                color: var(--winwise-button-secondary-color) !important
                background-color: none
                border: 1px solid var(--winwise-button-secondary-color)
                margin-right: 0 !important

                &:hover
                    color: var(--winwise-button-secondary-hover-color) !important

            &:hover:enabled
                background-color: var(--winwise-button-background-colour-hover) !important
                border: 1px solid var(--winwise-button-background-colour-hover) !important
                