@import './../../_all_vars.sass'

$collaborator-height: 88px

:root
  --sidebar-background-colour: #{$recall-dropdown-background}
  --collaborator-background-colour: #{$recall-file-preview-background}
  --winwise-button-background-colour: #{$recall-winwise-button-background}
  --winwise-button-border: #{$recall-cra-button-border}
  --winwise-button-text-color: #{$recall-primary-white}
  --winwise-button-secondary-text-colour: #{$recall-cra-button-text-color}
  --winwise-header-title-text: #{$recall-heading}
  --winwise-suggested-queries-background: #{$recall-input-field-background-colour}

div:global(.pageThemeDark)
  --sidebar-background-colour: #{$recall-bamboo-entity-sidebar-background-darkmode}
  --collaborator-background-colour: #{$recall-file-preview-background-darkmode}
  --winwise-button-background-colour: #{$recall-winwise-button-background-darkmode}
  --winwise-button-border: #{$recall-cra-button-border-darkmode}
  --winwise-button-text-color: #{$recall-cra-button-text-color}
  --winwise-button-secondary-text-colour: #{$secondary--text--colour}
  --winwise-header-title-text:#{$recall-text-color}
  --winwise-suggested-queries-background: #{$recall-menu-background-darkmode}


.opportunityThreadWrap
  display: flex
  width: 100%
  height: 100vh

.sidebarWrap
  background-color: var(--sidebar-background-colour)
  width: $recall-menu-width

  h3
    margin: 0
    color: var(--winwise-header-title-text)

  .resize
    height: calc(100vh - $collaborator-height)

  .collaborator
    background-color: var(--collaborator-background-colour)
    padding: 24px 16px
    display: flex
    justify-content: space-between
    align-items: center

    .settingIcon
      color: var(--winwise-button-background-colour)

    .badgeWrap
      display: flex
      align-items: center

      .badge
        width: 24px
        height: 22px
        border-radius: 50%
        top: 5px
        background-color: var(--winwise-button-background-colour)

      .text
        padding-left: 16px
 
  .manageFiles
    padding: 8px 16px

    .heading
      display: flex
      justify-content: space-between
      align-items: center
      margin-bottom: 8px

      button
        min-width:80px
        border-radius: 20px

        &:global(.is-secondary)
          color: var(--winwise-button-secondary-text-colour)
          border: 1px solid var(--winwise-button-secondary-text-colour)
  
  .threadHistory
    padding: 16px
    background-color: inherit

    .listThread
      border-left: 1px solid var(--winwise-header-divider)
      margin: 16px 0

      .threadItem
        display: flex
        align-items: center
        padding: 8px 16px
        color: var(--winwise-header-title-text)

        &.active
          color: var(--winwise-button-background-colour)

        &:hover
          color: var(--winwise-button-background-colour)
          cursor: pointer

        .name
          padding: 0 8px
          width: max-content
          max-width: 100%
          white-space: nowrap
          overflow: hidden
          text-overflow: ellipsis
    
    .newThreadBtn
      padding: 0

.chatSessionWrap
  flex: 1
  display: flex
  flex-direction: column
  padding: 8px 16px 100px
  height: 100vh
  overflow-y: auto

  .headerWrapper
    h2
      text-align: left
      margin-top: 0
      color: var(--winwise-header-title-text)

    .goBackBtn
      padding: 0px

  .tip
    margin-left: 8px

  > .winwiseQuestionBox
      width: -webkit-fill-available
      left: 285px
      margin: 0 0.7%

  .loadingResponse
    min-height: unset

  .waitResponseQuestion
    border-bottom: none

  .suggestedQueriesLayout
    position: relative
    width: 100%
    flex: 1

    .suggestedQueriesContent
      background-color: var(--winwise-suggested-queries-background)
      color: var(--winwise-header-title-text)
      position: absolute
      bottom: 10px
      width: 100%
      padding: 24px
      border-radius: 16px

      :global
        .checkbox-wrapper
          margin-bottom: 12px !important
          .icon-wrapper
            color: var(--winwise-header-title-text)
            font-size: 16px !important
            height: 8px !important
            margin: 0px

          .checkbox-group .checkbox-label
            &:last-child
              margin-bottom: 0 !important

            .checkbox
              background-color: unset
              border: 1px solid var(--winwise-header-title-text) !important
              height: 17px
              width: 17px

              &.is-checked
                background-color: unset

            .checkbox-text
              font-size: 13px
              font-weight: 400
              line-height: 20px
              color: var(--winwise-header-title-text) !important

.filenameWrap
  display: flex
  align-items: center
  margin-bottom: 8px

  &:last-child
    margin-bottom: 0

  svg
    margin-right: 8px
  
  span
    color: var(--winwise-header-title-text)
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
    flex: 1

.linkWrap
  display: flex
  align-items: center
  margin-bottom: 8px

  &:hover
    cursor: pointer

  .linkIcon
    margin-right: 8px
    top: 2px
  
  span
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
    flex: 1

.accordionWrap
  background-color: inherit !important

  :global
    .accordion-panel
      border: unset !important

      .heading-label-wrapper
        color: var(--winwise-button-background-colour)

      .material-icons.accordion-panel-icon
        color: var(--winwise-button-background-colour) !important

    .accordion-content
      background-color: inherit !important

      &:last-of-type
        border-bottom: unset !important

    .fileupload
      .dropzone
        border-color: var(--winwise-button-background-colour)

      .fileuploadicon
        color: $recall-file-upload-icon-color

      .filetitle
        span
          color: var(--winwise-header-title-text)

        a
          text-decoration: underline
          color: var(--winwise-button-background-colour)

.tooltipMessage
  div
    padding: 4px 0

.tooltipWrap
  span 
    padding: 8px 0

.linkTooltip
  word-break: break-word
  max-width: 250px