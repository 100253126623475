@import '../_all_vars'

:root
    --bamboo-button-background: #{$recall-bamboo-button-background}
    --bamboo-button-hover-background: #{$recall-bamboo-button-background-hover}
    --bamboo-button-primary-color: #{$recall-primary-white}
    --bamboo-button-secondary-color: #{$recall-primary-black}
    --winwise--button-text-color: #{$recall-primary-black}
    --winwise-button-background-colour: #{$winwise-button-background}
    --winwise-button-background-hover: #{$winwise-button-background-hover}
    --button-secondary-color: #{$recall-primary-black}

div:global(.pageThemeDark)
    --bamboo-button-background: #{$recall-bamboo-button-background-darkmode}
    --bamboo-button-hover-background: #{$recall-bamboo-button-background-hover-darkmode}
    --bamboo-button-primary-color: #{$recall-primary-black}
    --bamboo-button-secondary-color: #{$recall-primary-white}
    --winwise--button-text-color: #{$recall-primary-black}
    --winwise-button-background-colour: #{$winwise-button-background-darkmode}
    --winwise-button-background-hover: #{$winwise-button-background-hover-darkmode}
    --button-secondary-color: #{$recall-primary-white}

.bamboo
    border-radius: 24px !important

    &.iconRound,
    &.primary
        color: var(--bamboo-button-primary-color) !important
        background-color: var(--bamboo-button-background) !important
        border: 1px solid var(--bamboo-button-background) !important

        &:hover:enabled
            color: var(--bamboo-button-primary-color) !important
            background: var(--bamboo-button-background-hover) !important

        &:focus:enabled
            background: var(--bamboo-button-background) !important
            border-color: var(--bamboo-button-border-focus) !important
        
        &:active:enabled
            background: var(--bamboo-button-background-pressed) !important
        
        &:disabled
            color: var(--bamboo-button-text-disabled) !important
            background: var(--bamboo-button-background-disabled) !important
            border-color: var(--bamboo-button-background-disabled) !important
            pointer-events: none !important

    &.secondary
        color: var(--bamboo-button-secondary-color) !important
        background-color: none
        border: 1px solid var(--bamboo-form-input-border-color)
        margin-right: 0 !important
        

    &.custom
        background: none !important
        border: none !important
        padding-left: 0

        i
            color: var(--bamboo-button-background)

            span
                text-decoration: underline

        &:hover:enabled,
        &:active:enabled,
        &:focus:enabled
            background: none !important
            border: none !important

    &:hover:enabled,
    &:focus:enabled
        background-color: var(--bamboo-button-hover-background) !important
        color: var(--bamboo-button-secondary-color) !important
        border: 1px solid var(--bamboo-button-hover-background) !important


.winwise
    &.primary
        background: var(--winwise-button-background-colour) !important
        border: 1px solid var(--winwise-button-background-colour) !important

        &:hover
            background-color: var(--winwise-button-background-hover) !important
            border-color: var(--winwise-button-background-hover) !important

    &.secondary
        color: var(--button-secondary-color) !important
        background: none !important
        border: 1px solid var(--button-secondary-color) !important

    &.text
        color: var(--winwise-button-background-colour) !important
        background: none !important
        border: none !important

        span
            font-weight: bold