@import './_all_vars.sass'

:root
  --appTile-primary-text-colour: #{$primary--text--colour}
  --appTile-background-color-hover: #{$recall-app-tile-border}
  --appTile-icon-primary-color: #{$recall-main-dark}

div:global(.pageThemeDark)
  --appTile-primary-text-colour: #{$secondary--text--colour}
  --appTile-background-color-hover: #{$recall-menu-background-hover-darkmode}
  --appTile-icon-primary-color: #{$recall-green-300-darkmode}

.tile
  cursor: pointer
  padding: 16px
  border-radius: 8px
  min-width: 270px
  max-width: 33%
  display: flex
  flex-direction: row
  flex: 1
  color: var(--appTile-primary-text-colour)

  &:hover
    background-color: var(--appTile-background-color-hover)

  :global(.iconRecallMenu1)
    fill: var(--appTile-primary-text-colour)

  :global(.iconRecallMenu2)
    fill: var(--appTile-icon-primary-color)

  :global(.iconWinWise1)
    fill: var(--appTile-primary-text-colour)

  :global(.iconWinWise2)
    fill: var(--appTile-icon-primary-color)

  :global(.iconChatgtpMenu)
    fill: var(--appTile-primary-text-colour)

  :global(.iconCodeAssistant)
    fill: var(--appTile-primary-text-colour)

  :global(.iconBambooLogo)
    fill: var(--appTile-primary-text-colour)
  
  :global(.iconCra1)
    fill: var(--appTile-primary-text-colour)

  :global(.iconCra2)
    fill: var(--appTile-icon-primary-color)

  :global(.bhp)
    fill: var(--appTile-primary-text-colour)

  :global(.rioTintoLogoSvg)
    fill: var(--appTile-primary-text-colour)
    
  :global(.ausNetLogoSvg)
    fill: var(--appTile-primary-text-colour)
  
  :global(.pmApp1)
    fill: var(--appTile-primary-text-colour)
  
  :global(.pmApp2)
    fill: var(--appTile-icon-primary-color)

  :global(.clientSenseLogo)
    fill: var(--appTile-primary-text-colour)

  :global(.iconCustomRecall)
    fill: var(--appTile-primary-text-colour)


  .content
    font-family: inherit
    font-weight: 300
    text-align: left
    line-height: 28px
    color: var(var(--appTile-primary-text-colour))

  .logo
    padding-right: 8px
    height: 40px
    width: 40px
    align-self: flex-start

@media screen and (max-width: $breakpoint--desktop-large - 1)
  .tile
    min-width: 290px
    max-width: 50%

@media screen and (max-width: $breakpoint--desktop)
  .tile
    min-width: 270px
    max-width: 50%

@media screen and (min-width: 1400px) and (max-width: 1800px)
  .tile
    min-width: 32%

@media screen and (min-width: 770px) and (max-width: 858px)
  .tile
    min-width: 220px
    padding: 8px

@media screen and (max-width: $breakpoint--mobile)
  .tile
    min-width: 315px
    max-width: 90%