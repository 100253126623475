@import './_all_vars.sass'

:root
  $question-input-wrapper-background: rgba(200, 200, 200, 0)
  --question-input-wrapper-background: #{$question-input-wrapper-background}
  $question-input-topOpen-background: rgba(200, 200, 200, 0.8)
  --question-input-topOpen-background: #{$question-input-topOpen-background}
  $question-input-main-background: rgba(230, 230, 230, 0.8)
  --question-input-main-background: #{$question-input-main-background}
  --question-input-replace-text: #{$recall-code-replaced-text-color}

div:global(.pageThemeDark)
  --question-input-wrapper-background: #{$recall-menu-background-darkmode}
  --question-input-topOpen-background: #{$recall-question-box-darkmode}
  --question-input-main-background: #{$recall-menu-background-darkmode}
  --question-input-replace-text: #{$recall-code-replaced-text-color-darkmode}

.questionInputWrapper
  position: absolute
  border-radius: 40px
  bottom: 24px
  z-index: 999
  margin-right: 0.7%

  &.topOpen
    background-color: var(--question-input-topOpen-background)

    .questionInput
      background-color: var(--question-input-main-background)

  &.fullscreen
    left: 285px
    right: 10px

  .questionInputReplaceText
    width: 100%
    text-align: center
    padding: 16px
    font-size: 16px
    font-style: normal
    font-weight: 400
    color: var(--question-input-replace-text)

  .questionInput
    position: relative
    display: flex
    flex-direction: row
    gap: 12px
    border-radius: 40px
    padding: 16px
    background-color: var(--question-input-main-background)
    backdrop-filter: blur(2px)

    > span
      align-content: end

  .dropBox
    position: absolute
    width: 100%
    height: 100%
    border: 3px dotted $recall-file-drop-color
    background-color: $recall-file-drop-background
    top: 0
    left: 0
    z-index: 10
    border-radius: 40px
    display: flex
    flex-direction: row

    div
      flex: 1
      text-align: center
      align-self: center

      i
        color: $recall-file-drop-color
        margin-bottom: 8px
        font-size: 32px

@media screen and (max-width: $breakpoint--tablet + 1)
  .questionInputWrapper
    bottom: 16px
    left: 8px
    right: 8px

    &.fullscreen
      left: 60px
      right: 10px

@media screen and (max-width: $breakpoint--mobile)
  .questionInputWrapper
    left: 60px
    right: 10px

@media screen and (min-width: $breakpoint--mobile)
  .questionInputWrapper
    left: 60px
    right: 10px

@media screen and (min-width: $breakpoint--tablet)
  .questionInputWrapper
    left: 285px
    right: 10px

@media screen and (min-width: $breakpoint--desktop)
  .questionInputWrapper
    width: $recall-content-width-desktop
    left: calc((100vw - $recall-menu-width) / 2 - $recall-content-width-desktop / 2 + $recall-menu-width)

    &.fullscreen
      width: unset

@media screen and (min-width: $breakpoint--desktop-large)
  .questionInputWrapper
    width: $recall-content-width-desktop-large
    left: calc((100vw - $recall-menu-width) / 2 - $recall-content-width-desktop-large / 2 + $recall-menu-width)

    &.fullscreen
      width: unset
