@import './_all_vars.sass'

:root
  --card-primary-heading-colour: #{$primary--text--colour}
  --card-primary-text-colour: #{$primary--text--colour}
  --card-primary-border-colour: #{$recall-menu-background}
  --card-background-colour: #{$recall-primary-white}
  --card-background-colour-hover: #{$recall-app-card-border}

div:global(.pageThemeDark)
  --card-primary-heading-colour: #{$recall-primary-white}
  --card-primary-text-colour: #{$recall-primary-text-colour-darkmode}
  --card-primary-border-colour: #{$recall-menu-background-darkmode}
  --card-background-colour: #{$recall-menu-background-darkmode}
  --card-background-colour-hover: #{$recall-menu-background-hover-darkmode}

.chatCard
  margin: 1rem 0
  cursor: pointer
  border-radius: 8px
  min-width: 300px
  flex: 1
  display: flex
  flex-direction: column
  font-family: inherit
  font-weight: 300
  text-align: center
  color: var(--card-primary-text-colour)
  background-color: var(--card-background-colour)

  :global(.iconRecallMenu1)
    fill: var(--card-primary-heading-colour)

  :global(.iconRecallMenu2)
    fill: var(--card-primary-heading-colour)

  :global(.iconWinWise1)
    fill: var(--card-primary-heading-colour)

  :global(.iconWinWise2)
    fill: var(--card-primary-heading-colour)

  :global(.iconChatgtpMenu)
    fill: var(--card-primary-heading-colour)

  :global(.iconCodeAssistant)
    fill: var(--card-primary-heading-colour)

  :global(.iconBambooLogo)
    fill: var(--card-primary-heading-colour)

  :global(.iconCustomRecall)
    fill: var(--card-primary-heading-colour)
  
  :global(.bhp)
    fill: var(--card-primary-heading-colour)

  :global(.pmApp1)
    fill: var(--card-primary-heading-colour)

  :global(.pmApp2)
    fill: var(--card-primary-heading-colour)

  :global(.clientSenseLogo)
    fill: var(--card-primary-heading-colour)
  
  &.inactive
    cursor: unset

  &:hover
    background-color: var(--card-background-colour-hover)

  .content
    padding: 2rem
    border-top: 1px solid var(--card-primary-border-colour)
    border-left: 1px solid var(--card-primary-border-colour)
    border-right: 1px solid var(--card-primary-border-colour)
    border-radius: 8px 8px 0 0
    flex-grow: 1
    font-family: inherit

    &.inactive
      border-radius: 8px
      border: 1px solid var(--card-primary-border-colour)

    span:global(.pill-wrapper)
      margin: 3px 6px

      span
        font-family: inherit

    &.experimental
      background-image: url("../assets/experimental.png")
      background-repeat: no-repeat
      background-position: right bottom

    h1
      font-family: inherit
      font-size: 32px
      color: var(--card-primary-heading-colour)

      .logo
        height: 46px
        width: auto
        vertical-align: middle

    p
      font-family: inherit
      font-weight: 300
      color: var(--card-primary-text-colour)

  .accent
    padding: 1rem
    border-radius: 0 0 8px 8px
    font-family: inherit
    font-weight: 400

    i
      margin-left: 8px

@media screen and (min-width: $breakpoint--desktop-large + 1)
  .chatCard
    min-height: 400px

@media screen and (max-width: $breakpoint--tablet + 1)
  .chatCard
    margin: 0.5rem 0

    .content
      padding: 1rem

      h1
        margin: 0
