@import './../../_all_vars.sass'

:root
  --winwise-table-text: #{$recall-heading}
  --winwise-table-row-hover: #{$recall-cra-table-row-background-hover}
  --winwise-table-row-border-bottom: #{$recall-cra-table-row-background-hover}
  --winwise-table-tr-border-bottom: #{$recall-heading}
  --winwise-header-title-text: #{$recall-heading}
  --winwise-header-divider: #{$recall-cra-divider}
  --winwise-button-background-colour: #{$recall-winwise-button-background}
  --winwise-button-border: #{$recall-cra-button-border}
  --winwise-button-text-color: #{$recall-primary-white}
  --winwise-button-secondary-text-colour: #{$recall-cra-button-text-color}
  --winwise-desc-text-color: #{$winwise-file-upload-desc-color}

div:global(.pageThemeDark)
  --winwise-table-text: #{$secondary--text--colour}
  --winwise-table-row-hover: #{$recall-cra-table-row-background-hover-darkmode}
  --winwise-table-row-border-bottom: #{$recall-cra-table-row-border-bottom-darkmode}
  --winwise-table-tr-border-bottom: #{$recall-primary-white}
  --winwise-header-title-text:#{$recall-cra-header-text-darkmode}
  --winwise-header-divider: #{$recall-cra-divider-darkmode}
  --winwise-button-background-colour: #{$recall-winwise-button-background-darkmode}
  --winwise-button-border: #{$recall-cra-button-border-darkmode}
  --winwise-button-text-color: #{$recall-cra-button-text-color}
  --winwise-button-secondary-text-colour: #{$secondary--text--colour}
  --winwise-desc-text-color: #{$winwise-file-upload-desc-color-darkmode}

.accordionWrap
  background-color: inherit !important

  :global
    .accordion-panel
      &:last-of-type
        border-bottom: unset !important

      .heading-label-wrapper
        color: var(--winwise-button-background-colour)

      .material-icons.accordion-panel-icon
        color: var(--winwise-button-background-colour) !important

    .accordion-content
      background-color: inherit !important

    .fileupload
      .dropzone
        border-color: var(--winwise-button-background-colour)

      .fileuploadicon
        color: $recall-file-upload-icon-color

      .filetitle
        span
          color: var(--winwise-button-background-colour)
          text-decoration: underline

        a
          text-decoration: underline
          color: var(--winwise-button-background-colour)

.headerWrapper
  h2
    text-align: left
    margin-top: 0
    color: var(--winwise-header-title-text)
  
  .goBackBtn
    padding: 0

.descriptionWrap
  color: var(--winwise-header-title-text)

  .desc
    font-size: 16px
    color: var(--winwise-desc-text-color)

.uploadOptionWrapper
  margin: 24px 0

.urlInput
  margin-bottom: 28px

  input
        border-radius: 4px
        border: 1px solid var(--text-input-primary-colour-main) !important

  > label
    .input-field
      input
        border-radius: 4px
        border: 1px solid var(--text-input-primary-colour-main) !important

.actionButtons
  display: flex
  justify-content: end

  > button
      min-width:80px
      border-radius: 20px

      &:global(.is-secondary)
        color: var(--winwise-button-secondary-text-colour)
        border: 1px solid var(--winwise-button-secondary-text-colour)

      &:first-child
        margin-right: 12px

.fileTableWrap
  margin-top: 24px
  color: var(--winwise-header-title-text)

  .totalFileSize
    margin: 12px 0
    font-size: 14px

  table
    table-layout: fixed
    color: var(--winwise-table-text)

    thead tr
      border-bottom: 2px solid var(--winwise-table-tr-border-bottom) !important

    .cell
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap
      border-bottom: 1px solid var(--winwise-table-row-border-bottom) !important

      > .filenameWrap
        display: flex
        align-items: center

        svg
          margin-right: 8px
        
        span
          overflow: hidden
          text-overflow: ellipsis
          white-space: nowrap
          flex: 1

      > .lastModifiedWrap
        display: flex
        align-items: center

        > .fileUploading
            display: flex
            align-items: center

            span
              padding-left: 8px

        > .successIcon
            margin-right: 8px
            color: $recall-status-success

        > .errorIcon
            margin-right: 8px
            color: $recall-status-warning
    
    .date
      white-space: nowrap
      border-bottom: 1px solid var(--winwise-table-row-border-bottom) !important
    
    .action
      padding: 0 !important
      height: 0
      overflow: invisible
      border-bottom: 1px solid var(--winwise-table-row-border-bottom) !important

      .actionWrapper
        display: flex
        align-items: center
        width: 100%
        height: 100%
        justify-content: center

        .deleteIcon
          cursor: pointer
  
    .tableRow
      background-color: transparent !important
      td
        background-color: transparent !important

    .tableRow:hover
      background-color: var(--winwise-table-row-hover) !important

.addHandyLinkWrap
  display: flex
  justify-content: center
  margin-top: 32px

  .icon
    color: var(--winwise-button-background-colour)
    cursor: pointer

.handyLinksWrap
  .label
    color: var(--winwise-header-title-text)
    font-weight: bold
    padding-bottom: 16px
