@import '../_all_vars'

:root
    --bamboo-form-input-border-color: #{$recall-bamboo-form-input-border-color}
    --bamboo-form-input-focus-color: #{$recall-bamboo-form-input-focus-color}
    --bamboo-modal-close-icon-color: #{$recall-bamboo-close-modal-icon-color}
    --bamboo-modal-placeholder-color: #{$recall-bamboo-modal-placeholder-color}

    --cra-form-input-focus-color: #{$recall-cra-form-input-focus-color}
    --winwise-form-input-focus-color: #{$winwise-form-input-focus-color}

    --textarea-field-error-color: #{$recall-text-required-color}

div:global(.pageThemeDark)
    --bamboo-form-input-border-color: #{$recall-bamboo-form-input-border-color-darkmode}
    --bamboo-form-input-focus-color: #{$recall-bamboo-form-input-focus-color-darkmode}
    --bamboo-modal-close-icon-color: #{$recall-bamboo-close-modal-icon-color-darkmode}
    --bamboo-modal-placeholder-color: #{$recall-bamboo-modal-placeholder-color-darkmode}

    --cra-form-input-focus-color: #{$recall-cra-form-input-focus-color-darkmode}
    --winwise-form-input-focus-color: #{$winwise-form-input-focus-color-darkmode}

    --textarea-field-error-color: #{$recall-text-required-color}

div:global(.clear-icon)
    i
        color: var(--bamboo-modal-close-icon-color) !important

.bamboo
    &.input
        i
            color: var(--bamboo-form-input-border-color) !important

        input
            border: 1px solid var(--bamboo-form-input-border-color) !important
            border-radius: 4px
            &:hover
                border-color: var(--bamboo-form-input-focus-color) !important

            &:focus
                background-color: rgba(0,0,0,0) !important
                border-color: var(--bamboo-form-input-focus-color) !important

            &::placeholder
                color: var(--bamboo-modal-placeholder-color)
    &.input:hover
        i
            color: var(--bamboo-form-input-focus-color) !important


    &.textarea
        textarea
            border: 1px solid var(--bamboo-form-input-border-color) !important
            border-radius: 4px
            &:hover
                border-color: var(--bamboo-form-input-focus-color) !important

            &:focus
                background-color: rgba(0,0,0,0) !important
                border-color: var(--bamboo-form-input-focus-color) !important

            &:active:enabled
                background-color: rgba(0,0,0,0) !important
                border-color: var(--bamboo-form-input-focus-color) !important

            &::placeholder
                color: var(--bamboo-modal-placeholder-color)
        
    &.textareaError
        textarea
            border: 1px solid var(--textarea-field-error-color) !important
            border-radius: 4px

.cra
    &.input
        i
            color: var(--bamboo-form-input-border-color) !important

        input
            border: 1px solid var(--bamboo-form-input-border-color) !important
            border-radius: 4px
            &:hover
                border-color: var(--cra-form-input-focus-color) !important

            &:focus
                background-color: rgba(0,0,0,0) !important
                border-color: var(--cra-form-input-focus-color) !important

            &::placeholder
                color: var(--bamboo-modal-placeholder-color)
    &.input:hover
        i
            color: var(--cra-form-input-focus-color) !important


    &.textarea
        textarea
            border: 1px solid var(--bamboo-form-input-border-color) !important
            border-radius: 4px
            &:hover
                border-color: var(--cra-form-input-focus-color) !important

            &:focus
                background-color: rgba(0,0,0,0) !important
                border-color: var(--cra-form-input-focus-color) !important

            &:active:enabled
                background-color: rgba(0,0,0,0) !important
                border-color: var(--cra-form-input-focus-color) !important

            &::placeholder
                color: var(--bamboo-modal-placeholder-color)
            
    &.textareaError
        textarea
            border: 1px solid var(--textarea-field-error-color) !important
            border-radius: 4px
.winwise
    &.input
        i
            color: var(--bamboo-form-input-border-color) !important

        input
            border: 1px solid var(--bamboo-form-input-border-color) !important
            border-radius: 4px
            &:hover
                border-color: var(--winwise-form-input-focus-color) !important

            &:focus
                background-color: rgba(0,0,0,0) !important
                border-color: var(--winwise-form-input-focus-color) !important

            &::placeholder
                color: var(--bamboo-modal-placeholder-color)
    &.input:hover
        i
            color: var(--winwise-form-input-focus-color) !important

    &.textarea
        textarea
            border: 1px solid var(--bamboo-form-input-border-color) !important
            border-radius: 4px
            &:hover
                border-color: var(--winwise-form-input-focus-color) !important

            &:focus
                background-color: rgba(0,0,0,0) !important
                border-color: var(--winwise-form-input-focus-color) !important

            &:active:enabled
                background-color: rgba(0,0,0,0) !important
                border-color: var(--winwise-form-input-focus-color) !important

            &::placeholder
                color: var(--bamboo-modal-placeholder-color)
    
    &.textareaError
        textarea
            border: 1px solid var(--textarea-field-error-color) !important
            border-radius: 4px