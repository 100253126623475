@import './../../_all_vars.sass'

.chatSubscribeContainer
  width: 100%
  color: var(--winwise-header-title-text)

  .waitForTheResponse
    width: 100%
    display: flex
    flex-direction: column
    text-align: center
    padding-bottom: 50px

    .subTitle
      font-size: 20px

    p
      margin: 2px 0

      &.estimatedText
        font-size: 14px

  .subscribeWrap
    margin: 30px 0
    text-align: center
    color: var(--winwise-header-title-text)

    .description
      margin: 22px 0
      font-size: 14px

    .message
      font-size: 20px
      color: var(--winwise-button-background-colour)

    button
      border-radius: 20px
      padding: 0 22px
      
  