
@import './_all_vars.sass'

:root
  --winwise-button-background-colour: #{$recall-winwise-button-background}

div:global(.pageThemeDark)
  --winwise-button-background-colour: #{$recall-winwise-button-background-darkmode}

.loader
  width: 100%
  height: 100%
  min-height: 200px
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center

  i
    padding: 12px

  .description
    font-size: 14px
    margin: 12px 0

.winwise
  :global
    .spinner-container
      svg
        .spinner-circle
          stroke: var(--winwise-button-background-colour)

