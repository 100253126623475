@import './_all_vars.sass'

.suggestedQueriesLayout
    position: absolute
    border-radius: 40px
    bottom: 80px
    z-index: 999
    width: -webkit-fill-available
    left: 285px

    .suggestedQueriesContent
        background-color: var(--winwise-suggested-queries-background)
        color: var(--winwise-header-title-text)
        position: relative
        bottom: 30px
        width: -webkit-fill-available
        padding: 24px
        border-radius: 16px
        margin: 0 2% 0 0.7%

        span:global(.checkbox-wrapper)
            margin-bottom: 12px !important
            .icon-wrapper
            color: var(--winwise-header-title-text)
            font-size: 16px !important
            height: 8px !important
            margin: 0px

            div:global(.checkbox-group) 
            div:global(.checkbox-label)
            &:last-child
                margin-bottom: 0 !important

            div:global(.checkbox)
                background-color: unset
                border: 1px solid var(--winwise-header-title-text) !important
                height: 17px
                width: 17px

                &.is-checked
                    background-color: unset

                i:global(.icon-wrapper)
                    color: unset !important
                    font-size: 16px !important


            span:global(checkbox-text)
                font-size: 13px
                font-weight: 400
                line-height: 20px
                color: var(--winwise-header-title-text) !important