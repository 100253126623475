@import '../_all_vars'

:root
  --winwise-button-background-colour: #{$recall-winwise-button-background}
  --winwise-resize-button-colour: #{$recall-winwise-resize-icon}

div:global(.pageThemeDark)
  --winwise-button-background-colour: #{$recall-winwise-button-background-darkmode}
  --winwise-resize-button-colour: #{$recall-winwise-resize-icon-darkmode}

.noneSelect
  user-select: none
  -moz-user-select: none
  -webkit-user-select: none

.recallResizeWrapper
  display: flex
  flex-direction: column

  .firstComponent
    overflow-y: auto
    overflow-x: hidden

  .secondComponent
    flex: 1
    overflow-y: auto
    overflow-x: hidden
    z-index: 1

  .resizeBar
      background-color: var(--winwise-button-background-colour)
      width: 100%
      height: 15px
      display: flex
      justify-content: center
      align-items: center
      z-index: 1

      .resizeIcon
          cursor: row-resize

          .line
              display: block
              width: 25px
              height: 2px
              background-color: var(--winwise-resize-button-colour)

              &:first-child
              margin-bottom: 1px