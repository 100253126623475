@import './_all_vars.sass'

.pagination-theme-light
  --pagination-main-light: #{$recall-main-light}
  --pagination-main-dark: #{$recall-main-dark}
  --pagination-main-colour: #{$recall-primary-white}
  --pagination-button-background-colour: #{$recall-pagination-background}

.pagination-theme-dark
  --pagination-main-light: #{$recall-green-300-darkmode}
  --pagination-main-dark: #{$recall-green-200-darkmode}
  --pagination-main-colour: #{$recall-primary-black-darkmode}
  --pagination-button-background-colour: #{$recall-green-500-darkmode}

.dropdown-theme-light
  --dropdown-main-background-colour: #{$recall-dropdown-background}
  --dropdown-open-background-colour: #{$recall-editor-tool-bar-background}
  --dropdown-icon-colour: #{$recall-editor-tool-bar-background}
  --dropdown-icon-colour-hover: #{$recall-primary-white}
  --dropdown-primary-text-colour: #{$recall-primary-black}
  --dropdown-primary-text-colour-hover: #{$recall-primary-white}
  --dropdown-secondary-text-colour: #{$recall-primary-black}
  --dropdown-item-background-colour: #{$recall-dropdown-option-background}
  --dropdown-item-list-background-colour: #{$recall-dropdown-background}
  --dropdown-cra-filter-background: #{$recall-dropdown-background}
  --dropdown-cra-filter-background-hover: #{$recall-cra-dropdown-background-color-hover}
  --dropdown-cra-filter-checkbox: #{$recall-cra-filter-checkbox}
  --dropdown-cra-filter-checkbox-icon: #{$recall-primary-white}
  --dropdown-cra-filter-dropdown-background: #{$recall-dropdown-background}
  --dropdown-cra-filter-dropdown-background-hover: #{$recall-dropdown-background}
  --dropdown-cra-filter-dropdown-options-border: none
  --dropdown-item-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2)

.dropdown-theme-dark
  --dropdown-main-background-colour: #{$recall-menu-background-hover-darkmode}
  --dropdown-open-background-colour: #{$recall-menu-background-hover-darkmode}
  --dropdown-icon-colour: #{$recall-green-500-darkmode}
  --dropdown-icon-colour-hover: #{$recall-primary-white}
  --dropdown-primary-text-colour: #{$recall-primary-white}
  --dropdown-primary-text-colour-hover: #{$recall-primary-white}
  --dropdown-secondary-text-colour: #{$recall-primary-black}
  --dropdown-item-background-colour: #{$recall-green-200-darkmode}
  --dropdown-item-list-background-colour: #{$recall-pagination-items-background-darkmode}
  --dropdown-cra-filter-background: #{$recall-cra-dropdown-background-color}
  --dropdown-cra-filter-background-hover: #{$recall-cra-dropdown-background-color-hover-darkmode}
  --dropdown-cra-filter-checkbox: #{$recall-cra-filter-checkbox-darkmode}
  --dropdown-cra-filter-checkbox-icon: #{$recall-primary-black}
  --dropdown-cra-filter-dropdown-background: #{$recall-cra-dropdown-selected-background-darkmode}
  --dropdown-cra-filter-dropdown-background-hover: #{$recall-cra-dropdown-selected-background-darkmode}
  --dropdown-cra-filter-dropdown-options-border: #{$recall-cra-dropdown-selected-background-darkmode}
  --dropdown-item-box-shadow: none


.combobox-theme-light
  --combobox-primary-text-colour: #{$recall-primary-black}
  --combobox-primary-text-colour-hover: #{$recall-primary-black}
  --combobox-main-background-colour: #{$recall-menu-background-hover-darkmode}
  --combobox-disabled-background-colour: #{$recall-primary-white}
  --combobox-item-list-background-colour: #{$recall-pagination-items-background-darkmode}
  --combobox-item-background-colour: #{$recall-green-200-darkmode}
  --combobox-options-background-color: #{$recall-dropdown-background}
  --combobox-options-background-color-hover: #{$recall-dropdown-option-background}
  --combobox-options-border-color: #{$recall-main-dark}
  --combobox-input-background-color-hover: #{$recall-primary}
  --combobox-input-background-open: #{$recall-editor-tool-bar-background}

.combobox-theme-dark
  --combobox-primary-text-colour: #{$recall-primary-white}
  --combobox-primary-text-colour-hover: #{$recall-primary-black}
  --combobox-main-background-colour: #{$recall-menu-background-hover-darkmode}
  --combobox-disabled-background-colour: #{$recall-menu-background-hover-darkmode}
  --combobox-item-list-background-colour: #{$recall-pagination-items-background-darkmode}
  --combobox-item-background-colour: #{$recall-green-200-darkmode}
  --combobox-options-background-color: #{$recall-pagination-items-background-darkmode}
  --combobox-options-background-color-hover: #{$recall-green-200-darkmode}
  --combobox-options-border-color: #{$recall-menu-background-darkmode}
  --combobox-input-background-color-hover: #{$recall-menu-background-darkmode}
  --combobox-input-background-open: #{$recall-menu-background-hover-darkmode}

.checkbox-theme-light
  --checkbox-border-hover: #{$recall-primary}

.checkbox-theme-dark
  --checkbox-border-hover: #{$recall-green-500-darkmode}

.button-theme-light
  --button-icon-color: #{$recall-primary}

.button-theme-dark
  --button-icon-color: #{$recall-primary-white}

.overflowmenu-theme-light
  --overflowmenu-icon-colour: #{$recall-primary-black}
  --overflowmenu-icon-background-colour: #{$recall-bamboo-overflowmenu-icon-background}
  --overflowmenu-cra-icon-background-colour: #{$recall-cra-button-background}
  --overflowmenu-menu-options: #{$recall-bamboo-overflowmenu-menu-options}
  --overflowmenu-menu-options-hover: #{$recall-bamboo-overflowmenu-menu-options-hover}
  --overflowmenu-cra-menu-options-text-colour: #{$recall-text-menu}
  --overflowmenu-cra-menu-options-hover: #{$recall-cra-overflowmenu-menu-options-hover}
  --overflowmenu-winwise-icon-background-colour: #{$winwise-button-background}

.overflowmenu-theme-dark
  --overflowmenu-icon-colour: #{$recall-primary-white}
  --overflowmenu-icon-background-colour: #{$recall-bamboo-overflowmenu-icon-background-darkmode}
  --overflowmenu-cra-icon-background-colour: #{$recall-cra-overflowmenu-icon-background}
  --overflowmenu-menu-options: #{$recall-bamboo-overflowmenu-menu-options-darkmode}
  --overflowmenu-menu-options-hover: #{$recall-bamboo-overflowmenu-menu-options-hover-darkmode}
  --overflowmenu-cra-menu-options-text-colour: #{$recall-primary-white}
  --overflowmenu-cra-menu-options-hover: #{$recall-cra-overflowmenu-menu-options-hover-darkmode}
  --overflowmenu-winwise-icon-background-colour: #{$winwise-button-background-darkmode}

.multiline-input-theme-light
  --multiline-text-limit-colour: #{$recall-primary-black}

.multiline-input-theme-dark
  --multiline-text-limit-colour: #{$recall-primary-white}

.fileupload-theme-light
  --file-upload-primary-colour: #{$recall-bamboo-button-background}
  --file-upload-primary-colour-text: #{$recall-primary-black}
  --file-upload-button-colour: #{$recall-primary-white}
  --file-upload-button-hover-background: #{$recall-bamboo-button-background-hover}

.fileupload-theme-dark
  --file-upload-primary-colour: #{$recall-bamboo-button-background-darkmode}
  --file-upload-primary-colour-text: #{$recall-primary-white}
  --file-upload-button-colour: #{$recall-primary-black}
  --file-upload-button-hover-background: #{$recall-bamboo-button-background-hover-darkmode}

.text-input-theme-light
  --text-input-background-colour: #{$recall-input-field-background-colour}
  --text-input-icon-colour: #{$recall-input-field-icon-colour}

.text-input-theme-dark
  --text-input-background-colour: #{$recall-input-field-background-colour-darkmode}
  --text-input-icon-colour: #{$recall-input-field-icon-colour-darkmode}


// ------ Loging page buttons START -------
.login
  button.is-primary
    &.is-white
      color: black
      border-color: white
      background-color: white

      &:hover:enabled:not(.is-loading),
      &:focus:enabled:not(.is-loading)
        color: white
        border-color: #555
        background-color: #555 !important

  button.is-secondary
    &.is-white
      color: white
      border-color: white
      background-color: transparent

      &:hover:enabled:not(.is-loading),
      &:focus:enabled:not(.is-loading)
        color: white
        border-color: #999
        background-color: #999 !important

  button.is-text
    &.is-white
      color: white

      &:hover:enabled:not(.is-loading),
      &:focus:enabled:not(.is-loading)
        color: #999 !important
        border-color: transparent

// ------ File Upload Component -------
.fileupload

  .fileUploadIcon
    color: var(--file-upload-primary-colour)
    margin-bottom: 22px !important

  .dropzoneLabelIconContainer
    color: var(--file-upload-primary-colour)

.fileupload .dropzone
  border: 1px dashed var(--file-upload-primary-colour)
  border-radius: 2px

  .tooltip
    color: var(--file-upload-primary-colour-text)

    .tooltip-wrapper
      margin-right: 2px

  .fileContraints
    display: none !important

  .file-uploader-delete-container
    position: absolute
    right: 8px
    bottom: 8px

  .file-uploader-delete-container button
    background-color: var(--file-upload-primary-colour)
    border-color: var(--file-upload-primary-colour)

  .file-uploader-delete-container button:hover
    color: var(--file-upload-button-colour) !important
    background-color: var(--file-upload-button-hover-background) !important
    border-color: var(--file-upload-button-hover-background) !important

  .file-uploader-delete-container button:focus
    color: var(--file-upload-button-colour) !important
    background-color: var(--file-upload-primary-colour) !important
    border-color: var(--file-upload-button-hover-background) !important

.fileupload .file-upload-error
  margin-top: 8px
  color: #{$recall-status-warning} !important

.modal .fileupload
  width: 100% !important

// ------ Loging page buttons END -------

.pagination-wrapper
  .pagination-list
    button.pagination-chevron
      i.material-icons.chevron-icon
        color: var(--pagination-main-light)

        &:hover
          background-color: var(--pagination-main-dark)
          color: var(--pagination-main-colour)

    button.pagination-item
      color: var(--pagination-main-light)
      &.is-active
        color: var(--pagination-main-colour)
        background-color: var(--pagination-button-background-colour)

      &:hover
        background-color: var(--pagination-main-dark)
        color: var(--pagination-main-colour)

.historySearchInput
  flex: 1
  min-width: 300px

  > label
    .input-field 
      input
        background-color: var(--text-input-background-colour)
        border: none
        border-radius: 25px
        padding-left: 46px !important

      input::placeholder
        color: var(--text-input-icon-colour)
      
      .custom-icon
        left: 16px !important
        font-size: 1.1rem
        color: var(--text-input-icon-colour)
        

.field-wrapper
  > label
    .input-field
      .search-icon
        color: $recall-main-light

  > label .input-field.is-dropdown:not(.is-disabled).is-open input
    background-color: var(--combobox-input-background-open)

  > label .input-field.is-dropdown.is-open input:hover
    background-color: var(--combobox-input-background-open)

  > label .input-field.is-dropdown:not(.is-disabled) input
    background-color: var(--combobox-disabled-background-colour)

  > label .input-field.is-dropdown input:hover
    background-color: var(--combobox-input-background-color-hover)

  > label .input-field.is-error input
    color: $recall-status-warning
    border: 1px solid $recall-status-warning !important

  > label .input-field.is-error i
    color: $recall-status-warning !important

.field-wrapper > label .textarea-field .text-limit
  color: var(--multiline-text-limit-colour)

.dropdown-wrapper
  color: var(--dropdown-primary-text-colour)

  .dropdown-content
    div.dropdown-selected
      background: var(--dropdown-main-background-colour)
      border: 1px solid var(--dropdown-main-background-colour)
      border-radius: 4px
      &:hover, &:focus
        color: var(--dropdown-primary-text-colour-hover)
        .dropdown-down-icon
          color: var(--dropdown-icon-colour-hover)
          &:hover
            color: var(--dropdown-icon-colour-hover)
      .dropdown-down-icon
        color: var(--dropdown-icon-colour)

      &.is-open
        background: var(--dropdown-open-background-colour)
        border: 1px solid var(--dropdown-open-background-colour)
        color: var(--dropdown-primary-text-colour-hover)

        i
          transform: rotate(180deg)
        
        .filterIcon
          transform: none !important

    .dropdown-options
      max-height: 250px
      overflow: auto
      background: var(--dropdown-item-list-background-colour)
      border-radius: 4px

      .dropdown-item
        &:hover
          color: var(--dropdown-secondary-text-colour)
          background: var(--dropdown-item-background-colour)

    & > .dropdown-selected.is-open > .dropdown-down-icon
      color: var(--dropdown-icon-colour-hover)

  .history-dropdown-filter .dropdown-options
    .dropdown-item span
      max-width: 100%
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
  
  .craFilterDropdown 
    .dropdown-selected
      background-color: var(--dropdown-cra-filter-dropdown-background) !important
      border: 1px solid var(--dropdown-cra-filter-dropdown-background) !important
      color: var(--dropdown-primary-text-colour) !important

      &:hover
        background-color: var(--dropdown-cra-filter-dropdown-background-hover)
        border: 1px solid var(--dropdown-cra-filter-dropdown-background-hover)
        color: var(--dropdown-primary-text-colour) !important

    .dropdown-options
      background-color: var(--dropdown-cra-filter-background)
      border: 1px solid var(--dropdown-cra-filter-dropdown-options-border) !important
      box-shadow: var(--dropdown-item-box-shadow)

      .dropdown-item 
        .checkbox
          background-color: transparent
          border: 1px solid var(--dropdown-cra-filter-checkbox) !important    
          i
            color: var(--dropdown-cra-filter-checkbox-icon)
      
          &.is-indeterminate
            background-color: var(--dropdown-cra-filter-checkbox)      
            border: 1px solid var(--dropdown-cra-filter-checkbox) !important   
      
          &.is-checked
            background-color: var(--dropdown-cra-filter-checkbox)

  .craFilterDropdown .dropdown-options .dropdown-item:hover
    background-color: var(--dropdown-cra-filter-background-hover) !important

.combobox-wrapper
  color: var(--combobox-primary-text-colour)

  & .combobox-content .options-wrapper .options
    background-color: var(--combobox-options-background-color)

  & .combobox-content .options-wrapper
    border: 1px solid var(--combobox-options-border-color)

  & .combobox-content .options-wrapper .options li:hover
    background-color: var(--combobox-options-background-color-hover)
    color: var(--combobox-primary-text-colour-hover)

.recall-icon-button
  button.button
    &.is-icon
      color: $recall-main-light
      background: transparent

      &:disabled
        color: $recall-main-light
        background: transparent

        &:hover
          color: $recall-main-light

      &:focus,
      &:hover
        color: white

.toast-container
  .top-right
    top: 84px
    right: 40px

.overflow-menu-wrapper:hover
  background-color: $recall-main-darker

  i
    color: white !important

.overflow-menu-wrapper
  &.is-open
    background-color: $recall-main-darker

    i
      color: white !important

  .overflow-menu-options
    background-color: $recall-dropdown-option-background

    .overflow-menu-item:hover
      background-color: $recall-main-dark
      color: white

@media screen and (min-width: $breakpoint--desktop-large)
  .report-list-container
    .container
      min-width: 1140px

.field-wrapper.edit-input > label .textarea-field textarea
  @include lato-regular
  height: 50vh !important
  min-height: 100px
  resize: none

.tooltip-wrapper.answer-actions
  .tooltip-popup
    span
      padding-top: 20px
      padding-left: 20px
      padding-right: unset

  span
    font-size: 12px

.tooltip-wrapper
  .tooltip-popup
    font-size: 14px
    p
      font-size: 14px

.container
  margin: 0 auto

  &.is-fluid
    max-width: 100%

.checkbox-label .checkbox:hover
  border: 1px solid var(--checkbox-border-hover)

.answer-actions .button.is-icon.is-default
  color: var(--button-icon-color)

.entity-overflow-menu, .entity-overflow-menu.is-open, .entity-overflow-menu.is-default
  background-color: transparent

  i
    color: var(--overflowmenu-icon-colour) !important
  &:hover, &.is-default.is-open
    background-color: var(--overflowmenu-icon-background-colour)

    i
      color: var(--overflowmenu-menu-options) !important

  .overflow-menu-options
    background-color: var(--overflowmenu-menu-options)
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2)

    .overflow-menu-item
      color: var(--overflowmenu-icon-colour)

    .overflow-menu-item:hover
      background-color: var(--overflowmenu-menu-options-hover)
      color: var(--overflowmenu-icon-colour)

.property-overflow-menu
  .overflow-menu-wrapper, .overflow-menu-wrapper.is-open, .overflow-menu-wrapper.is-default
    background-color: transparent
    i
      color: var(--overflowmenu-icon-colour) !important
    &:hover, &.is-default.is-open
      background-color: var(--overflowmenu-icon-background-colour) !important

      i
        color: var(--overflowmenu-menu-options) !important

    .overflow-menu-options
      background-color: var(--overflowmenu-menu-options)
      box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2)

      .overflow-menu-item:hover
        background-color: var(--overflowmenu-menu-options-hover)
        color: var(--overflowmenu-icon-colour)

.cra-overflow-menu, .cra-overflow-menu.is-open, .cra-overflow-menu.is-default
  background-color: transparent

  i
    color: var(--overflowmenu-icon-colour) !important
  &:hover, &.is-default.is-open
    background-color: var(--overflowmenu-cra-icon-background-colour)

    i
      color: var(--overflowmenu-menu-options) !important

  .overflow-menu-options
    background-color: var(--overflowmenu-menu-options)
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2)

    .overflow-menu-item
      color: var(--overflowmenu-cra-menu-options-text-colour)

    .overflow-menu-item:hover
      background-color: var(--overflowmenu-cra-menu-options-hover)
      color: var(--overflowmenu-cra-menu-options-text-colour)

.winwise-overflow-menu, .winwise-overflow-menu.is-open, .winwise-overflow-menu.is-default
  background-color: transparent

  i
    color: var(--overflowmenu-icon-colour) !important
  &:hover, &.is-default.is-open
    background-color: var(--overflowmenu-winwise-icon-background-colour)

    i
      color: var(--overflowmenu-menu-options) !important

  .overflow-menu-options
    background-color: var(--overflowmenu-menu-options)
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2)

    .overflow-menu-item
      color: var(--overflowmenu-cra-menu-options-text-colour)

    .overflow-menu-item:hover
      background-color: var( --overflowmenu-winwise-icon-background-colour)
      color: var(--overflowmenu-cra-menu-options-text-colour)

.is-sortable-header
  &:hover
    background-color: unset !important
    color: unset !important

@media screen and (min-width: $breakpoint--mobile)
  .container
    margin: 0 auto
    max-width: 100%

@media screen and (min-width: $breakpoint--tablet)
  .container
    margin: 0 auto
    max-width: 100%

@media screen and (min-width: $breakpoint--desktop)
  .container
    max-width: 100%

@media screen and (min-width: $breakpoint--desktop-large)
  .container
    max-width: 1140px
