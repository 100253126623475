@import '../_all_vars.sass'

:root
  --winwise-header-title-text: #{$recall-heading}
  --winwise-button-background-colour: #{$winwise-button-background}

div:global(.pageThemeDark)
  --winwise-header-title-text:#{$recall-cra-header-text-darkmode}
  --winwise-button-background-colour: #{$winwise-button-background-darkmode}
  
.createNewThreadModal
  h2
    text-align: left
    color: var(--winwise-header-title-text)
  
  h4
    color: var(--winwise-button-background-colour)

  i:global(.modal-close-icon)
    color: var(--winwise-button-background-colour)

  .actionButtons
    display: flex
    justify-content: center
    align-items: center

    .or
      padding: 0 12px

    > button
        border-radius: 20px
        flex: 1
