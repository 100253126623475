@import './_all_vars.sass'

:root
  --winwise-table-text: #{$recall-heading}
  --winwise-table-row-hover: #{$recall-cra-table-row-background-hover}
  --winwise-table-row-border-bottom: #{$recall-cra-table-row-background-hover}
  --winwise-table-tr-border-bottom: #{$recall-heading}
  --winwise-header-title-text: #{$recall-heading}
  --winwise-header-divider: #{$recall-cra-divider}
  --winwise-button-background-colour: #{$winwise-button-background}
  --winwise-button-border: #{$recall-cra-button-border}
  --winwise-button-background-colour-hover: #{$recall-cra-button-background-hover}
  --winwise-button-text-color: #{$recall-primary-white}
  --winwise-button-text-color-hover: #{$secondary--text--colour}
  --winwise-checkbox-border: #{$recall-cra-checkbox-border-color}
  --winwise-pill-status-one: #{$recall-cra-pill-status-one}
  --winwise-pill-status-two: #{$recall-cra-pill-status-two}
  --winwise-pill-status-three: #{$recall-cra-pill-status-three}

div:global(.pageThemeDark)
  --winwise-table-text: #{$secondary--text--colour}
  --winwise-table-row-hover: #{$recall-cra-table-row-background-hover-darkmode}
  --winwise-table-row-border-bottom: #{$recall-cra-table-row-border-bottom-darkmode}
  --winwise-table-tr-border-bottom: #{$recall-primary-white}
  --winwise-header-title-text:#{$recall-cra-header-text-darkmode}
  --winwise-header-divider: #{$recall-cra-divider-darkmode}
  --winwise-button-background-colour: #{$winwise-button-background-darkmode}
  --winwise-button-border: #{$recall-cra-button-border-darkmode}
  --winwise-button-background-colour-hover: #{$recall-cra-button-background-hover-darkmode}
  --winwise-button-text-color: #{$recall-cra-button-text-color}
  --winwise-button-text-color-hover: #{$recall-cra-button-text-color}
  --winwise-checkbox-border: #{$recall-cra-checkbox-border-color-darkmode}
  --winwise-pill-status-one: #{$recall-cra-pill-status-one-darkmode}
  --winwise-pill-status-two: #{$recall-cra-pill-status-two-darkmode}
  --winwise-pill-status-three: #{$recall-cra-pill-status-three-darkmode}


.headerTitle
  margin-bottom: 0px

.headerText
  text-align: center
  p
    color: var(--winwise-header-title-text)
    margin: 8px 0px
    font-weight: 400

.winwiseFooter
  display: flex
  justify-content: center
  align-items: center
  margin-top: 20px

  .winwiseButton
    display: flex
    justify-content: center
    align-items: center
    background: var(--winwise-button-background-colour) !important
    border: var(--winwise-button-border) !important
    border-radius: 20px
    padding: 12px 24px
    color: var(--winwise-button-text-color) !important

    > span
      line-height: normal
    
    &:hover
      background: var(--winwise-button-background-colour-hover) !important

.header
  display: flex
  flex-direction: column
  justify-content: space-between
  align-items: center
  gap: 30px
  color: var(--winwise-header-title-text)

  h2 
    margin-bottom: 0px  
    color: var(--winwise-header-title-text)
    font-size: 40px


  .headerSubText
    font-size: 16px
    font-weight: 400
    text-align: center
    width: 80%
    margin-top: 0
    color: var(--winwise-table-text)

  .createTopButton
    display: flex
    justify-content: center
    align-items: center
    padding: 12px 24px
    background: var(--winwise-button-background-colour) !important
    border: var(--winwise-button-border) !important
    border-radius: 20px
    color: var(--winwise-button-text-color) !important

    &:hover
      background: var(--winwise-button-background-colour-hover) !important
      color: var(--winwise-button-text-color-hover) !important

.winwiseTable
  table
    table-layout: fixed
    color: var(--winwise-table-text)

    thead tr
      border-bottom: 2px solid var(--winwise-table-tr-border-bottom) !important

    .cell
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap
      border-bottom: 1px solid var(--winwise-table-row-border-bottom) !important
    
    .date
      white-space: nowrap
      border-bottom: 1px solid var(--winwise-table-row-border-bottom) !important
    
    .action
      padding: 0 !important
      height: 0
      overflow: invisible
      border-bottom: 1px solid var(--winwise-table-row-border-bottom) !important

      .actionWrapper
        display: flex
        align-items: center
        width: 100%
        height: 100%
        justify-content: center
  
    .winwiseTableRow
      background-color: transparent !important
      td
        background-color: transparent !important

    .winwiseTableRow:hover
      background-color: var(--winwise-table-row-hover) !important

    .nomicMapLink
      font-size: 14px
      color: var(--winwise-table-text)

    .tooltipMapUrl
      span:nth-child(2)
        width: auto !important
        max-width: 100% !important

  span:global(.winwise-pill-status-1)
    background-color: var(--winwise-pill-status-one)    

  span:global(.winwise-pill-status-2)
    background-color: var(--winwise-pill-status-two)   
    
  span:global(.winwise-pill-status-3)
    background-color: var(--winwise-pill-status-three)   

  .urlAnchor
    color: unset !important
    white-space: nowrap
    max-width: 99%
    overflow: hidden
    text-overflow: ellipsis
  
  .dateCell
    white-space: nowrap

.publishModalContent
  display: flex
  flex-direction: column

  .checkbox
    background-color: red !important

  .nomicUrl
    font-size: 14px
    color: var(--winwise-button-background-colour) !important

  .terms
    margin-top: 20px
    display: flex

    div:global(.checkbox)
      border-color: var(--winwise-checkbox-border) !important
      &:hover
        background: var( --winwise-button-background-colour) !important

    div:global(.is-checked)  
      border-color: var( --winwise-button-background-colour) !important
      background: var( --winwise-button-background-colour) !important
  
.filterContainer
  width: 170px
  margin: 12px 0 26px auto

  i:nth-of-type(2)
    color: $recall-winwise !important
  
  .filterIcon
    margin-right: 8px
    transition: none
    display: inline-block
    vertical-align: middle

.loaderContainer
  margin: 30px auto

.divider
  width: 100%
  margin-top: 3rem
  border: 1px solid var(--winwise-header-divider)

.manageAccessIconWrap
  display: inline-block
  cursor: pointer

@media screen and (max-width: $breakpoint--mobile)
  .headerTitle
    margin: 36px 0 8px 0 !important

  .header
    display: flex
    gap: 10px

    .headerSubText
      width: 100%

